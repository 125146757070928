import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  components: {
    DrawerToggle: () =>
      import(
        /* webpackChunkName: "default-drawer-toggle" */
        './widgets/DrawerToggle.vue'
      ),
    HomeButton: () =>
      import(
        /* webpackChunkName: "default-go-home" */
        './widgets/GoHome.vue'
      ),
    Notifications: () =>
      import(
        /* webpackChunkName: "default-notifications" */
        './widgets/Notifications.vue'
      ),
  },
  computed: {
    ...mapGetters('app', ['isMobile', 'isResize']),
  },
})
export default class BaseBar extends Vue {
  isMobile!: boolean

  resize = 0
  isResize!: number

  @Watch('isResize', { immediate: true })
  onResizeChange (val) {
    this.resize = val
  }
}
