
  import { Component, Watch } from 'vue-property-decorator'
  import { mapActions, mapGetters } from 'vuex'

  import { toRouteRecord } from '@/router/helpers'
  import { Resource } from '@/entities/public'
  import { Filter } from '@/entities/public/Resource/interfaces'
  import BaseBar from '@/layouts/BaseBar'

@Component({
  methods: {
    ...mapActions('resources', ['setActive', 'setFilter']),
  },
  computed: {
    ...mapGetters('resources', ['all']),
    ...mapGetters('resources/datatables', ['datatableLoading']),
  },
})
  export default class AppBar extends BaseBar {
  private all!: Array<Resource>;
  private setActive!: (resource?: Resource) => void;
  private setFilter!: (filter?: Filter) => void;

  datatableLoading!: boolean;

  resource = -1;
  option = -1;

  created () {
    const { $route: { params: { resource } }, targets } = this
    this.resource = (resource && targets) ? targets.map(({ slug }) => slug).indexOf(resource) : 0

    const { $route: { query: { filter } }, filters } = this
    this.option = (filter && filters) ? filters.map(({ name }) => name).indexOf(filter as string) : 0
  }

  get targets (): Array<Resource> {
    const { $route, all } = this
    const { name } = toRouteRecord($route)
    return all.filter(({ route }) => route.name && route.name === name)
  }

  get target (): Resource | undefined {
    const { resource, targets } = this
    if (!targets) return undefined

    return targets[resource]
  }

  get filters (): Array<Filter> | undefined {
    const { target } = this
    if (!target) return undefined

    return target.filters
  }

  get activeFilter () {
    const { option, filters } = this
    if (!filters) return undefined

    return filters[option]
  }

  @Watch('target')
  onTargetChange (resource: Resource, prev: Resource) {
    if (!resource || prev === resource) return
    const query = Object.assign({}, this.$route.query)
    this.$router.replace({ path: resource.path, query }).catch(() => {
    })
    this.setActive(resource)
    this.setFilter()
  }

  @Watch('activeFilter')
  onFilterChange (filter: Filter, prev: Filter) {
    if (!filter || prev === filter) return
    const query = Object.assign({}, this.$route.query, { filter: filter.name })
    this.$router.replace({ query }).catch(() => {
    })

    this.setFilter(filter)
  }

  @Watch('isResize', { immediate: true })
  onResizeChange (val) {
    this.resize = val
  }
  }
